<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">Consider the balanced equation shown below:</p>

      <p class="pl-14 mb-5">
        <stemble-latex content="$\ce{A(g) + B(g) -> C(g) + D(g)}$" />
      </p>

      <p class="mb-5">
        Determine the average rate of reaction if you start with
        <number-value :value="concentration" unit="\text{M}" />
        of ALL chemical species (reagents and products) and after
        <number-value :value="time" unit="\text{min,}" />
        the concentration of
        <stemble-latex content="$\text{D}$" />
        is found to be
        <number-value :value="concDfinal" unit="\text{mol/L.}" />
      </p>

      <calculation-input
        v-model="inputs.rate"
        class="mb-2"
        prepend-text="$\text{Rate:}$"
        append-text="$\text{M/min}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question363',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        rate: null,
      },
    };
  },
  computed: {
    time() {
      return this.taskState.getValueBySymbol('time').content;
    },
    concentration() {
      return this.taskState.getValueBySymbol('concentration').content;
    },
    concDfinal() {
      return this.taskState.getValueBySymbol('concDfinal').content;
    },
  },
};
</script>
